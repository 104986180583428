import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Button, FormHelperText, LinearProgress } from '@material-ui/core';
import axios from 'axios';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import { useParams } from 'react-router-dom';
import SITE_URL from '../../config/constant.mjs';

export default function AddAudio() {
    const classes = useStyles();
    const [message, setMessage] = useState();
    const [audioFile, setAudioFile] = useState();
    const [audio, setAudio] = useState();
    const [playlists, setPlaylists] = useState();
    const [isUploading, setIsUploading] = useState(false);
    const [input, setInput] = useState({
        titleEng: '',
        titleUr: '',
        description: '',
        playlistId: '',
        audio: '',
    });

    const { id } = useParams();

    useEffect(() => {
        if (id) {
            axios.get(`${SITE_URL}/audios/${id}`)
                .then(res => {
                    const { data } = res;
                    setInput({
                        'titleUr': data.titleUr,
                        'titleEng': data.titleEng,
                        'description': data.description,
                        'playlistId': data.playlistId,
                        'audio': '',
                    })
                    setAudio(data);
                })
                .catch(err => {
                    console.log(err)
                })
        }

        axios.get(`${SITE_URL}/playlists`)
            .then(res => {
                setPlaylists(res.data);
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    // form input handling
    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.currentTarget.name]: e.currentTarget.value
        });
        if (e.target.files) {
            setAudioFile(e.target.files);
        }
    }

    let formData = new FormData();
    formData.append('titleUr', input.titleUr)
    formData.append('titleEng', input.titleEng)
    formData.append('description', input.description)
    formData.append('playlistId', input.playlistId)
    formData.append('audio', audioFile && audioFile["0"])

    const config = {
        headers: { 'content-type': 'multipart/form-data' },
    }

    // save audios
    const submit = (e) => {
        e.preventDefault();
        setIsUploading(true);

        axios.post(`${SITE_URL}/audios`, formData, config)
            .then(res => {
                let newItem = res.data._id;

                // add this audio id to playlist
                axios.get(`${SITE_URL}/playlists/${input.playlistId}`)
                    .then(res => {
                        let existingItems = res.data.items;

                        axios.put(`${SITE_URL}/playlists/${input.playlistId}`, {
                            items: [...existingItems, newItem],
                        })
                            .then(res => {
                                const notificationData = {
                                    "title" : input.titleEng,
                                    "body": "New lecture has been uploaded.",
                                   }
                                setIsUploading(false);
                                setMessage(res.status == 200 && 'Sucessfully added.');
                                if (res.status === 200) {
                                    axios.post(`${SITE_URL}/notifications`, notificationData )
                                    .catch(err => console.log(err))
                                    setInput({
                                        titleEng: '',
                                        titleUr: '',
                                        description: '',
                                        audio: '',
                                        playlistId: '',
                                    })
                                }
                            })
                            .catch(err => console.log(err))
                    })
                    .catch(err => console.log(err))
            })
            .catch(err => console.log(err))
    }

    const removeAudioFromOldPlaylist = (oldPlaylist) => {
        let oldPlaylistDetails = playlists.find(playlist => playlist._id === oldPlaylist);
        const { items } = oldPlaylistDetails;

        const index = items.indexOf(audio._id);
        if (index > -1) {
            items.splice(index, 1)
        }

        // update playlist items
        axios.put(`${SITE_URL}/playlists/${oldPlaylist}`, {
            items: items,
        })
            .then(res => {
                if (res.status != 200) {
                    setMessage('Something went wrong while updating oldParent. Please, try again.')
                    return;
                }
            })
            .catch(err => console.log(err))
    }


    const addAudioToNewPlaylist = (newPlaylist) => {
        let newPlaylistDetails = playlists.find(playlist => playlist._id === newPlaylist);
        const { items } = newPlaylistDetails;

        axios.put(`${SITE_URL}/playlists/${newPlaylist}`, {
            items: [...items, id]
        })
            .then(res => {
                if (res.stats != 200) {
                    setMessage('Something went wrong while updating new playlist. Please, try again.')
                    return;
                }
            })
            .catch(err => console.log(err))
    }

    const updateAudio = () => {
        // get old playlist id and remove current audio from items
        // get new playlist id and add current audio to items
        
        let oldPlaylist = audio.playlistId;
        let newPlaylist = input.playlistId;

        if (oldPlaylist != newPlaylist) {
            removeAudioFromOldPlaylist(oldPlaylist);
            addAudioToNewPlaylist(newPlaylist);
        }

        // update audio
        axios.put(`${SITE_URL}/audios/${id}`, {
            titleEng: input.titleEng,
            titleUr: input.titleUr,
            description: input.description,
            playlistId: input.playlistId,
        })
            .then(res => {
                setMessage(res.status === 200 ? 'Successfully updated.' : 'Could not update!')
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <Container>
            <div className={classes.root}>
                <div>
                    <ValidatorForm onSubmit={id ? updateAudio : submit}>
                        <TextValidator
                            className={classes.textField}
                            name="titleEng"
                            id="titleEng"
                            value={input.titleEng}
                            label="English Title"
                            style={{ margin: 8 }}
                            placeholder="Dars 1, Part 3"
                            fullWidth
                            margin="normal"
                            onChange={handleInputChange}
                            variant="outlined"
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                        />
                        <FormHelperText style={{ margin: 8 }}> Add Title in English</FormHelperText>

                        <TextValidator
                            className={classes.textField}
                            name="titleUr"
                            id="titleUr"
                            value={input.titleUr}
                            label="Urdu Title"
                            style={{ margin: 8 }}
                            placeholder="درس ۱، حصہ ۳"
                            fullWidth
                            margin="normal"
                            onChange={handleInputChange}
                            variant="outlined"
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                        />
                        <FormHelperText style={{ margin: 8 }}>Add Title in Urdu</FormHelperText>

                        <TextValidator
                            className={classes.textField}
                            name="description"
                            id="description"
                            value={input.description}
                            label="Description"
                            style={{ margin: 8 }}
                            fullWidth
                            margin="normal"
                            onChange={handleInputChange}
                            variant="outlined"
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                        />
                        <FormHelperText style={{ margin: 8 }}>Add description</FormHelperText>
                        {
                            !id &&
                            <>
                                <TextValidator
                                    className={classes.textField}
                                    name="audio"
                                    id="audio"
                                    value={input.audio}
                                    style={{ margin: 8 }}
                                    fullWidth
                                    margin="normal"
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    type="file"
                                    validators={["required"]}
                                    errorMessages={["This field is required"]}
                                />
                                <FormHelperText style={{ margin: 8 }}>Upload audio</FormHelperText>
                            </>
                        }
                        <SelectValidator
                            name="playlistId"
                            value={input.playlistId}
                            fullWidth
                            variant="outlined"
                            style={{ margin: 8 }}
                            SelectProps={{
                                native: true
                            }}
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                            onChange={handleInputChange}
                        >
                            <option value=""> None </option>
                            {
                                playlists && playlists.length > 0 && playlists.map((playlist) => {
                                    return <option key={playlist._id} value={playlist._id}> {playlist.nameUr} </option>
                                })
                            }
                        </SelectValidator>
                        <FormHelperText style={{ margin: 8 }}>Select Playlist</FormHelperText>


                        <div>
                            <Button style={{ margin: 8 }} variant="contained" color="primary" type="submit">
                                {id ? 'Update' : 'Submit'}
                            </Button>
                        </div>
                        <div>
                            {message}
                        </div>
                    </ValidatorForm>
                    {isUploading && <LinearProgress />}
                </div>
            </div>
        </Container>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '75ch',
    },
}));