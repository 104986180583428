import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Button, Select, FormHelperText } from '@material-ui/core';
import axios from 'axios';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useParams } from 'react-router-dom';
import SITE_URL from '../../config/constant.mjs';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '75ch',
    },
}));

export default function AddCategory() {
    const classes = useStyles();
    const [message, setMessage] = useState();
    const [category, setCategory] = useState();
    const [categories, setCategories] = useState([]);
    const [input, setInput] = useState({
        englishTitle: '',
        urduTitle: '',
        parentId: 'none',
    });
    const { id } = useParams();

    // fetching categories
    useEffect(() => {
        if (id) {
            axios.get(`${SITE_URL}/categories/${id}`)
                .then(res => {
                    setInput({
                        englishTitle: res.data.nameEng,
                        urduTitle: res.data.nameUr,
                        parentId: res.data.parentId,
                    })
                    setCategory(res.data);
                })
                .catch(err => {
                    console.log(err)
                })
        }

        axios.get(`${SITE_URL}/categories`)
            .then(res => {
                if (res.data && res.data.length > 0) {
                    id ?
                        setCategories(res.data.filter(data => data._id != id))
                        : setCategories(res.data);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }, id ? [] : [input.parentId]);

    // form input handling

    const handleInputChange = (e) => setInput({
        ...input,
        [e.currentTarget.name]: e.currentTarget.value
    });

    // update category with subCategory
    const addSubCategory = (parentId, subCategoryId) => {
        axios.get(`${SITE_URL}/categories/${parentId}`)
            .then(res => {
                const { data } = res;

                axios.put(`/categories/${parentId}`, {
                    subCategories: [...data.subCategories, subCategoryId]
                })
                    .then(res => {
                        setMessage(res.status == 200 && 'Sucessfully added.');
                    })
                    .catch(err => {
                        console.log(err)
                    })
                return res.data;
            })
            .catch(err => {
                console.log(err)
            })
    }

    // create category
    const Submit = () => {
        axios.post(`${SITE_URL}/categories`, {
            nameUr: input.urduTitle,
            nameEng: input.englishTitle,
            parentId: input.parentId,
        })
            .then(res => {
                if (input.parentId !== "none") {
                    addSubCategory(input.parentId, res.data._id)
                }
                setMessage('Sucessfully added.');
                setInput({
                    englishTitle: '',
                    urduTitle: '',
                });
            })
            .catch(err => {
                console.log(err);
            })
    }

    const removeCategoryFromOldParent = (oldParent) => {
        // remove ${id} oldParent subCategories
        let oldParentDetails = categories.find(category => category._id === oldParent);

        // get oldParent details
        let oldParentSubCategories = oldParentDetails.subCategories;

        // remove category id from subCategory of old parent
        const index = oldParentSubCategories.indexOf(category._id);
        if (index > -1) {
            oldParentSubCategories.splice(index, 1);
        }

        // update database of old parent
        axios.put(`${SITE_URL}/categories/${oldParent}`, {
            subCategories: oldParentSubCategories,
        })
            .then(res => {
                if (res.status != 200) {
                    setMessage('Something went wrong while updating oldParent. Please, try again.')
                    return;
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const addCategoryToNewParent = (newParent) => {
        // add category id to subCategories of new parent
        let newParentDetails = categories.filter(category => category._id === newParent);

        // get previousParent details
        let newParentSubCategories = newParentDetails[0].subCategories;

        // update database with new category id
        axios.put(`${SITE_URL}/categories/${newParent}`, {
            subCategories: [...newParentSubCategories, category._id]
        })
            .then(res => {
                if (res.status != 200) {
                    setMessage('Something went wrong while updating newParent. Please, try again.')
                    return;
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    // updateCategory
    const updateCategory = () => {
        let oldParent = category.parentId;
        let newParent = input.parentId;

        if (oldParent != newParent) {
            if (oldParent === "none" && newParent != "none")  {
                addCategoryToNewParent(newParent);    
            } else 
            if (newParent === "none" && oldParent != "none") {
                removeCategoryFromOldParent(oldParent);
            } else {
                removeCategoryFromOldParent(oldParent);
                addCategoryToNewParent(newParent)
            }
        }

        // Update parentid
        axios.put(`${SITE_URL}/categories/${id}`, {
            nameEng: input.englishTitle,
            nameUr: input.urduTitle,
            parentId: input.parentId,
        })
            .then(res => {
                res.status === 200 ? setMessage('Successfully updated.') : setMessage('Could not update!')
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <Container>
            <div className={classes.root}>
                <div>
                    <ValidatorForm onSubmit={id ? updateCategory : Submit}>
                        <TextValidator
                            className={classes.textField}
                            name="englishTitle"
                            value={input.englishTitle}
                            id="englishTitle"
                            label="English Title"
                            style={{ margin: 8 }}
                            placeholder="Tafseer e Qurna"
                            fullWidth
                            margin="normal"
                            onChange={handleInputChange}
                            variant="outlined"
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                        />
                        <FormHelperText style={{ margin: 8 }}> Add Title in English</FormHelperText>

                        <TextValidator
                            className={classes.textField}
                            name="urduTitle"
                            value={input.urduTitle}
                            id="urduTitle"
                            label="Urdu Title"
                            style={{ margin: 8 }}
                            placeholder="تفسیرِ قرآن"
                            fullWidth
                            margin="normal"
                            onChange={handleInputChange}
                            variant="outlined"
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                        />
                        <FormHelperText style={{ margin: 8 }}>Add Title in Urdu</FormHelperText>

                        <Select
                            name="parentId"
                            value={input.parentId ? input.parentId : 'none'}
                            native
                            fullWidth
                            variant="outlined"
                            style={{ margin: 8 }}
                            onChange={handleInputChange}
                        >
                            <option value='none'> None </option>
                            {
                                categories && categories.length > 0 && categories.map((category) => {
                                    return <option key={category._id} value={category._id}> {category.nameUr} </option>
                                })
                            }
                        </Select>
                        <FormHelperText style={{ margin: 8 }}>Select parent category</FormHelperText>
                        <div>
                            <Button style={{ margin: 8 }} variant="contained" color="primary" type="submit">
                                {id ? 'Update' : 'Submit'}
                            </Button>
                        </div>
                        <div>
                            {message}
                        </div>
                    </ValidatorForm>
                </div>
            </div>
        </Container>
    );
}
