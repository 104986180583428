import React, { useState, useEffect } from 'react';
import {
  makeStyles,
} from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import axios from 'axios';
import { Link } from 'react-router-dom';
import SITE_URL from '../../config/constant.mjs';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: {
    backgroundColor: '#585555',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
    marginBottom: 20,
  },
});

export default function BasicTable() {
  const classes = useStyles();
  const [playlists, setPlaylists] = useState([]);
  const [message, setMessage] = useState();
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, playlists.length - page * rowsPerPage);

  useEffect(() => {
    axios.get(`${SITE_URL}/playlists`)
      .then(res => {
        if (res.data && res.data.length > 0) {
          setPlaylists(res.data)
          setIsLoading(false);
        } else {
          setMessage('No record found.')
          setIsLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
      })

    axios.get(`${SITE_URL}/categories`)
      .then(res => {
        if (res.data && res.data.length > 0) {
          setCategories(res.data)
        }
      })
  }, []);

  const deletePlaylist = (playlistId, categoryId) => {

    // if playlist has items, then don not remove
    let playlistDetails = playlists.find(playlist => playlist._id === playlistId);
    if (playlistDetails.items.length > 0) {
      setMessage('Can not delete playlist. Please remove audios under this playlist.')
      return;
    }

    // get category id from playlist and remove playlists from category
    let categoryDetails = categories.find(category => category._id === categoryId)
    let { playlists: currentPlaylists } = categoryDetails;

    axios.delete(`${SITE_URL}/playlists/${playlistId}`)
      .then(res => {
        if (res.status === 200) {
          // remove playlist from category
          const index = currentPlaylists.indexOf(playlistId);
          if (index > -1) {
            currentPlaylists.splice(index, 1);
          }
          // update category
          axios.put(`${SITE_URL}/categories/${categoryId}`, {
            playlists: currentPlaylists,
          })
            .then(res => {
              const filteredPlaylist = playlists.filter(playlist => playlist._id != playlistId)
              setPlaylists(filteredPlaylist);
              setMessage(res.data.message);
            })
            .catch(err => console.log(err))
        }
      })
      .catch(err => console.log(err))
  }

  return (
    <>
      <div>
        {message}
        <span style={{ textAlign: 'left' }}>
          <h2> Playlists Management </h2>
        </span>
        <span style={{ textAlign: 'right' }}>
          <Link to="/AddPlaylist">
            <h4> Add Playlist </h4>
          </Link>
        </span>
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow style={{ backgroundColor: '#80808033' }}>
              <TableCell>English Title </TableCell>
              <TableCell>Urdu Title</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          {isLoading &&
            <div className={classes.spinner}>
              <CircularProgress />
            </div>
          }
          <TableBody>
            {
              playlists && playlists.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(playlist => {
                  let categoryDetails;
                  let categoryName;
                  if (categories.length > 0) {
                    categoryDetails = categories.find(category => category._id === playlist.categoryId)
                    categoryName = categoryDetails ? categoryDetails.nameUr : '';
                  }

                  let date = new Date(playlist.createdAt);

                  return (
                    <TableRow key={playlist._id}>
                      <TableCell> {playlist.nameEng} </TableCell>
                      <TableCell> {playlist.nameUr} </TableCell>
                      <TableCell> {categoryName} </TableCell>
                      <TableCell> {date.toDateString()} </TableCell>
                      <TableCell>
                        <>
                          <IconButton component={Link} to={`/AddPlaylist/${playlist._id}`} aria-label="edit"> <CreateIcon /> </IconButton>
                          <IconButton onClick={() => deletePlaylist(playlist._id, playlist.categoryId)} aria-label="delete"> <DeleteIcon /> </IconButton>
                        </>
                      </TableCell>
                    </TableRow>
                  )
                })
            }
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={playlists.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}