import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Button, FormHelperText } from '@material-ui/core';
import axios from 'axios';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import { useParams } from 'react-router-dom';
import SITE_URL from '../../config/constant.mjs';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '75ch',
    },
}));

export default function AddPlaylist() {
    const classes = useStyles();
    const [message, setMessage] = useState();
    const [playlist, setPlaylist] = useState();
    const [categories, setCategories] = useState([]);
    const [input, setInput] = useState({
        nameEng: '',
        nameUr: '',
        categoryId: '',
    });
    const { id } = useParams();

    // fetching playlists
    useEffect(() => {
        axios.get(`${SITE_URL}/categories`)
            .then(res => {
                setCategories(res.data);
            })
            .catch(err => {
                console.log(err);
            })

        if (id) {
            axios.get(`${SITE_URL}/playlists/${id}`)
                .then(res => {
                    setInput({
                        nameEng: res.data.nameEng,
                        nameUr: res.data.nameUr,
                        categoryId: res.data.categoryId,
                    })
                    setPlaylist(res.data);
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, []);

    // form input handling

    const handleInputChange = (e) => setInput({
        ...input,
        [e.currentTarget.name]: e.currentTarget.value
    });

    // create playlist
    const Submit = () => {
        axios.post(`${SITE_URL}/playlists`, {
            nameUr: input.nameUr,
            nameEng: input.nameEng,
            categoryId: input.categoryId,
        })
            .then(res => {
                if (res.status === 200) {
                    let newPlaylist = res.data._id;
                    axios.get(`${SITE_URL}/categories/${input.categoryId}`)
                        .then(res => {
                            let existingPlaylists = res.data.playlists;

                            axios.put(`${SITE_URL}/categories/${input.categoryId}`, {
                                playlists: [...existingPlaylists, newPlaylist],
                            })
                                .then(res => {
                                    let message = res.status === 200 ? 'Successfully added' : 'Could not add successfully.';
                                    setMessage(message)
                                    setInput({
                                        nameEng: '',
                                        nameUr: '',
                                        categoryId: '',
                                    });
                                })
                                .catch(err => console.log(err));
                        })
                        .catch(err => console.log(err))
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const removePlaylistFromOldCategory = (oldCategory) => {
        let oldCategoryDetails = categories.find(category => category._id === oldCategory);
        const { playlists } = oldCategoryDetails;

        const index = playlists.indexOf(id);
        if (index > -1) {
            playlists.splice(index, 1)
        }

        axios.put(`${SITE_URL}/categories/${oldCategory}`, {
            playlists: playlists,
        })
            .then(res => {
                if (res.status != 200) {
                    setMessage('Something went wrong while updating oldParent. Please, try again.')
                    return;
                }
            })
            .catch(err => console.log(err))
    }

    const addPlaylistToNewCategory = (newCategory) => {
        let newCategoryDetails = categories.find(category => category._id === newCategory);
        const { playlists } = newCategoryDetails;

        axios.put(`${SITE_URL}/categories/${newCategory}`, {
            playlists: [...playlists, id]
        })
        .then(res => {
            if (res.status != 200) {
                setMessage('Something went wrong while updating oldParent. Please, try again.')
                return;
            }
        })
        .catch(err => console.log(err))

    }

    // updatePlaylist
    const updatePlaylist = () => {

        // get old category and remove playlist from category
        // get new category and add playlist to category

        let oldCategory = playlist.categoryId;
        let newCategory = input.categoryId;

        if (oldCategory != newCategory) {
            removePlaylistFromOldCategory(oldCategory);
            addPlaylistToNewCategory(newCategory);
        }

        axios.put(`${SITE_URL}/playlists/${id}`, {
            nameEng: input.nameEng,
            nameUr: input.nameUr,
            categoryId: input.categoryId,
        })
            .then(res => {
                res.status === 200 ? setMessage('Successfully updated.') : setMessage('Could not update!')
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <Container>
            <div className={classes.root}>
                <div>
                    <ValidatorForm onSubmit={id ? updatePlaylist : Submit}>
                        <TextValidator
                            className={classes.textField}
                            name="nameEng"
                            value={input.nameEng}
                            id="nameEng"
                            label="English Title"
                            style={{ margin: 8 }}
                            placeholder="Tafseer e Quran"
                            fullWidth
                            margin="normal"
                            onChange={handleInputChange}
                            variant="outlined"
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                        />
                        <FormHelperText style={{ margin: 8 }}> Add Title in English</FormHelperText>

                        <TextValidator
                            className={classes.textField}
                            name="nameUr"
                            value={input.nameUr}
                            id="nameUr"
                            label="Urdu Title"
                            style={{ margin: 8 }}
                            placeholder="تفسیرِ قرآن"
                            fullWidth
                            margin="normal"
                            onChange={handleInputChange}
                            variant="outlined"
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                        />
                        <FormHelperText style={{ margin: 8 }}>Add Title in Urdu</FormHelperText>

                        <SelectValidator
                            name="categoryId"
                            value={input.categoryId}
                            fullWidth
                            variant="outlined"
                            style={{ margin: 8 }}
                            SelectProps={{
                                native: true
                            }}
                            validators={["required"]}
                            errorMessages={["This field is required"]}
                            onChange={handleInputChange}
                        >
                            <option value=""> None </option>
                            {
                                categories && categories.length > 0 && categories.map(category => (
                                    <option key={category._id} value={category._id}> {category.nameUr} </option>
                                ))
                            }
                        </SelectValidator>

                        <div>
                            <Button style={{ margin: 8 }} variant="contained" color="primary" type="submit">
                                {id ? 'Update' : 'Submit'}
                            </Button>
                        </div>
                        <div>
                            {message}
                        </div>
                    </ValidatorForm>
                </div>
            </div>
        </Container>
    );
}
