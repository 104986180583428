import React from 'react';
import Layout from './Layout';
import { Box } from '@material-ui/core';

const Dashboard = () => {
    return (
        <Box>
            <Layout />
        </Box>
    );
}

export default Dashboard;
