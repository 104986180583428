import React from 'react';
import {
  makeStyles,
} from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: {
    backgroundColor: '#585555',
  }
});

export default function BasicTable() {
  const classes = useStyles();

  return (
    <>
      <h2> Tags Management </h2>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow style={{backgroundColor: '#80808033'}}>
              <TableCell>English Title </TableCell>
              <TableCell>Urdu Title</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key='1'>
              <TableCell> Akhlaaq </TableCell>
              <TableCell> اخلاق </TableCell>
              <TableCell>21:40:33 </TableCell>
              <TableCell>
                <>
                  <IconButton aria-label="delete"> <CreateIcon /> </IconButton>
                  <IconButton aria-label="delete"> <DeleteIcon /> </IconButton>
                </>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}