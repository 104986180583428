import React, { useEffect, useState } from 'react';
import {
  makeStyles,
} from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  CircularProgress,
  Box,
  TablePagination
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import { Link } from 'react-router-dom';
import axios from 'axios';
import SITE_URL from '../../config/constant.mjs';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: {
    backgroundColor: '#585555',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
    marginBottom: 20,
  }
});


export default function BasicTable() {
  const classes = useStyles();

  const [categories, setCategories] = useState([]);
  const [message, setMessage] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, categories.length - page * rowsPerPage);


  useEffect(() => {
    axios.get(`${SITE_URL}/categories`)
      .then(res => {
        if (res.data && res.data.length > 0) {
          setCategories(res.data)
          setIsLoading(false);
        } else {
          setMessage('No record found.')
          setIsLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
      })
  }, []);

  const deleteCategory = (id, e) => {
    e.preventDefault();

    // Show error if category has sub categories
    axios.get(`${SITE_URL}/categories/${id}`)
      .then(res => {
        const { subCategories, parentId, playlists } = res.data;
        if (playlists.length > 0) {
          setMessage("First, please delete playlists under this category.")
          return;
        }
        if (subCategories.length > 0) {
          setMessage("Please remove sub categories first.")
        } else {
          // If category has parent then remove its id from parent
          if (parentId != 'none') {
            const parentCategory = categories.filter(category => category._id === parentId)
            const subCategories = parentCategory[0].subCategories;

            // remove id from subcategories
            const index = subCategories.indexOf(id);
            if (index > -1) {
              subCategories.splice(index, 1);
            }

            // update parent with new subcategories
            axios.put(`${SITE_URL}/categories/${parentId}`, {
              subCategories: subCategories,
            })
              .then(res => {
                if (res.status != 200) {
                  setMessage('Could not delete category. Please, try again.');
                  return;
                }
              })
              .catch(err => {
                console.log(err)
              })
          }
          // if there is no error then delete
          axios.delete(`${SITE_URL}/categories/${id}`)
            .then(res => {
              const filteredCategories = categories.filter(category => category._id != id)
              setCategories(filteredCategories);
              setMessage(res.data.message);
            })
            .catch(err => {
              console.log(err);
            })
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <>
      <div>
        {message}
        <span style={{ textAlign: 'left' }}>
          <h2> Categories Management </h2>
        </span>
        <span style={{ textAlign: 'right' }}>
          <Link to="/AddCategory">
            <h4> Add Category </h4>
          </Link>
        </span>
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>

            <TableRow style={{ backgroundColor: '#80808033' }}>
              <TableCell>English Title </TableCell>
              <TableCell>Urdu Title</TableCell>
              <TableCell>Parent</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading &&
              <Box className={classes.spinner}>
                <CircularProgress />
              </Box>
            }
            {
              categories && categories.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(c => {
                  let categoryDetails;
                  let categoryName;
                  if (categories.length > 0) {
                    categoryDetails = categories.find(category => category._id === c.parentId);
                    categoryName = categoryDetails ? categoryDetails.nameEng : '-'
                  }
                  let date = new Date(c.createdAt);

                  return (
                    <TableRow key={c._id}>
                      <TableCell> {c.nameEng} </TableCell>
                      <TableCell> {c.nameUr} </TableCell>
                      <TableCell> {c.parentId && categoryName} </TableCell>
                      <TableCell> {date.toDateString()} </TableCell>
                      <TableCell>
                        <>
                          <IconButton component={Link} to={`/AddCategory/${c._id}`} aria-label="edit"> <CreateIcon /> </IconButton>
                          <IconButton onClick={e => deleteCategory(c._id, e)} aria-label="delete"> <DeleteIcon /> </IconButton>
                        </>
                      </TableCell>
                    </TableRow>
                  )
                })
            }
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={categories.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}
