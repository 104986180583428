import React, { useState, useEffect } from 'react';
import {
  makeStyles,
} from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TablePagination,
  CircularProgress,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import axios from 'axios';
import { Link } from 'react-router-dom';
import SITE_URL from '../../config/constant.mjs';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: {
    backgroundColor: '#585555',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
    marginBottom: 20,
  },
});

export default function AudiosManagement() {
  const classes = useStyles();

  let [playlists, setPlaylists] = useState([]);
  let [audios, setAudios] = useState([]);
  const [message, setMessage] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, audios.length - page * rowsPerPage);

  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, audios.length - page * rowsPerPage);

  useEffect(() => {
    axios.get(`${SITE_URL}/audios`)
      .then(res => {
        if (res.data && res.data.length > 0) {
          setAudios(res.data);
          setIsLoading(false);
        } else {
          setMessage('No record found.');
          setIsLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setMessage('No record found!!');
      })

    axios.get(`${SITE_URL}/playlists`)
      .then(res => {
        setPlaylists(res.data);
      })
      .catch(err => {
        console.log(err)
      })
  }, []);

  const deleteAudio = (audioId, playlistId) => {

    // get playlist details and remove audio from playlist items
    const playlistDetils = playlists.find(playlist => playlist._id === playlistId);
    const { items: existingItems } = playlistDetils;

    axios.delete(`${SITE_URL}/audios/${audioId}`)
      .then(res => {
        if (res.status === 200) {
          // remove audio from items
          const index = existingItems.indexOf(audioId);
          if (index > -1) {
            existingItems.splice(index, 1)
          }
          // update playlist
          axios.put(`${SITE_URL}/playlists/${playlistId}`, {
            items: existingItems,
          })
            .then(res => {
              if (res.status === 200) {
                const filteredAudios = audios.filter(audio => audio._id != audioId)
                setAudios(filteredAudios);
                setMessage(res.data.message);
              }
            })
            .catch(err => console.log(err))
        }
      })
      .catch(err => console.log(err))
  }

  return (
    <>
      <div>
        {message}
        <span style={{ textAlign: 'left' }}>
          <h2> Audios Management </h2>
        </span>
        <span style={{ textAlign: 'right' }}>
          <Link to="/AddAudio">
            <h4> Add Audio </h4>
          </Link>
        </span>
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow style={{ backgroundColor: '#80808033' }}>
              <TableCell>English Title </TableCell>
              <TableCell>Urdu Title</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Playlist</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading &&
              <div className={classes.spinner}>
                <CircularProgress />
              </div>
            }
            {
              audios &&
              audios.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(audio => {
                  let playlistDetails;
                  let playlistName;
                  if (playlists.length > 0) {
                    playlistDetails = playlists.find(playlist => playlist._id === audio.playlistId)
                    playlistName = playlistDetails ? playlistDetails.nameUr : '';
                  }
                  let date = new Date(audio.createdAt);
                  return (
                    <TableRow key={audio._id}>
                      <TableCell> {audio.titleEng} </TableCell>
                      <TableCell> {audio.titleUr} </TableCell>
                      <TableCell> {audio.isPublished ? 'Published' : 'Not Published'} </TableCell>
                      <TableCell> {playlistName} </TableCell>
                      <TableCell> {date.toDateString()} </TableCell>
                      <TableCell>
                        <>
                          <IconButton component={Link} to={`/AddAudio/${audio._id}`} aria-label="edit"> <CreateIcon /> </IconButton>
                          <IconButton onClick={() => deleteAudio(audio._id, audio.playlistId)} aria-label="delete"> <DeleteIcon /> </IconButton>
                        </>
                      </TableCell>
                    </TableRow>
                  )
                })
            }
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={audios.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}