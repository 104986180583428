import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Switch, Route } from 'react-router-dom';
import ManageUsers from '../user/ManageUsers';
import ManageAudios from '../audio/ManageAudios';
import ManagePlaylists from '../playlist/ManagePlaylist';
import AddPlaylist from '../playlist/AddPlaylist';
import ManageCategories from '../category/ManageCategories';
import ManageTags from '../tag/ManageTags';
import AddCategory from '../category/AddCategory';
import AddAudio from '../audio/AddAudio';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
}));

const Content = () => {
    const classes = useStyles();

    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <Switch>
                <Route path="/users">
                    <ManageUsers />
                </Route>
                <Route path="/audios">
                    <ManageAudios />
                </Route>
                <Route path="/categories">
                    <ManageCategories />
                </Route>
                <Route exact path="/AddCategory">
                    <AddCategory />
                </Route>
                <Route path="/AddCategory/:id">
                    <AddCategory />
                </Route>
                <Route exact path="/AddAudio">
                    <AddAudio />
                </Route>
                <Route path="/AddAudio/:id">
                    <AddAudio />
                </Route>
                <Route path="/playlists">
                    <ManagePlaylists />
                </Route>
                <Route exact path="/AddPlaylist">
                    <AddPlaylist />
                </Route>
                <Route path="/AddPlaylist/:id">
                    <AddPlaylist />
                </Route>
                <Route path="/tags">
                    <ManageTags />
                </Route>
            </Switch>
        </main>
    );
}

export default Content;